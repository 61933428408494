// Angular modules
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// External modules
import Swal from 'sweetalert2';
import { Observable, Subject, delay, takeUntil } from 'rxjs';

// Services
import { StoreService } from '@services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

// Components
import { AlertComponent } from '@blocks/alert/alert.component';

// Environment
import { environment } from '@env/environment';

// Base class
import { BaseComponent } from './base';
import { EventBusService } from '@services/event-bus.service';

@Component({
  template: '',
})
export class BaseAddComponent<T extends object> extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild(AlertComponent) alertComponent!: AlertComponent;

  protected toggleDetails = false;

  protected environment = environment;

  protected formGroup!: FormGroup;

  protected entity!: any;

  protected routeToEdit = '';
  protected routeToList = '';

  protected operationTitle = '';
  protected operationSubtitle = '';

  protected ngUnsubscribe = new Subject<boolean>();

  // -------------------------------------------------------------------------------
  // #region Constructor & Init & Destroy ---------------------------------------------
  // -------------------------------------------------------------------------------

  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected router: Router,
    protected override logger: NGXLogger,
    protected translateService: TranslateService,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,
  ) {
    super(location, titleService, storeService, logger, activatedRoute, eventBusService);
  }

  override ngOnInit(): void {

    super.ngOnInit();

    this.logger.info('ngOnInit from BaseAddComponent');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
  // #endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Actions ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  /**
   * Resets form and init FormControl values with entity object assigning default values
   */
  protected setDefaultValuesToFormGroup(): void {

    this.formGroup.reset(this.entity);

    this.setTransientValuesToFormGroup();
  }

  protected setTransientValuesToFormGroup() {

  }


  /**
   * Set data from form to the entity object
   */
  protected setFormDataValuesToEntity() {
    Object.assign(this.entity, { ...this.formGroup.value });

    this.transformTransientValuesToEntityValue();
  }

  protected transformTransientValuesToEntityValue() {

  }

  // #endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Save Action -----------------------------------------------------------
  // -------------------------------------------------------------------------------

  protected save(saveObs$: Observable<T>, findByIdObs$?: any) {

    this.beforeStartQuery();

    saveObs$
      .pipe(
        delay(environment.delayFake),
        takeUntil(this.ngUnsubscribe))

      .subscribe({
        next: (newEntity: T) => {

          this.entity = newEntity;

          this.fillRelatedData(findByIdObs$);
        },
        error: (e) => {

          this.errorInQuery();
          this.showErrortMsg();
          this.logger.error(e);

        },
        complete: () => {
          this.logger.info('save complete');
        }
      });
  }

  // get entity related data
  private fillRelatedData(findByIdObs$: any) {

    if (!findByIdObs$) {

      this.doWhenEntityAdded();
      this.completeSave();

    } else {

      findByIdObs$(this.entity.id)
        .pipe(
          delay(environment.delayFake),
          takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (filledEntity: T) => {

            this.entity = filledEntity;

            this.doWhenEntityAdded();
            this.completeSave();

          },
          error: (e: any) => {

            this.errorInQuery();
            this.showErrortMsg();
            this.logger.error(e);
          },
          complete: () => {
            this.logger.info('fillRelatedData complete');
          }
        });
    }
  }

  protected doWhenEntityAdded() {
  }


  // completes save processes
  private completeSave() {

    this.setDefaultValuesToFormGroup();

    this.storeService.setIsLoading(false);

    this.showAlertMsg();

    //this.showModalMsg();

  }

  // #endregion
  // ------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Delete Action ---------------------------------------------------------
  // -------------------------------------------------------------------------------

  protected delete(obs$: any) {

    this.beforeStartQuery();

    obs$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: (res: any) => {

          this.storeService.setIsLoading(false);
          this.logger.info('Eliminando en delete');

          this.doWhenDeleteOperationFinish(res);

        },
        error: (e: any) => {

          this.errorInQuery();

          Swal.fire({
            icon: 'error',
            title: 'Importante',
            text: 'No se ha podido realizar el borrado',
          })
          this.logger.error(e);
        },
        complete: () => {
          this.logger.info('complete')
        }
      });

  }

  protected doWhenDeleteOperationFinish(item?: any) {
  }

  // #endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Specifif Operation ----------------------------------------------------
  // -------------------------------------------------------------------------------
  protected specificOperation(obs$: any) {

    this.beforeStartQuery();

    obs$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: (res: any) => {

          this.storeService.setIsLoading(false);
          this.logger.info('Ejecutando una operación genérica');

          this.doWhenSpecificOperationFinish(res);

        },
        error: (e: any) => {

          this.errorInQuery();

          Swal.fire({
            icon: 'error',
            title: 'Importante',
            text: 'No se ha podido realizar la operación',
          })
          this.logger.error(e);
        },
        complete: () => {
          this.logger.info('complete')
        }
      });

  }

  protected doWhenSpecificOperationFinish(item?: any) {
  }

  // #endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Miscellaneous ---------------------------------------------------------
  // -------------------------------------------------------------------------------

  protected showAlertMsg(content: string = 'Registro guardado correctamente', type: string = 'success') {
    this.alertComponent.content = content;
    this.alertComponent.type = type;
    this.alertComponent.dismissibleMiliseconds = this.alertComponent.dismissibleMiliseconds ?? environment.dismissibleMiliseconds;
    this.alertComponent.dismissAlert();
  }

  protected showErrortMsg(content: string = 'Error al guardar el registro', type: string = 'danger') {
    this.alertComponent.content = content;
    this.alertComponent.type = type;
    this.alertComponent.dismissibleMiliseconds = 5000;
    this.alertComponent.dismissible = false;
    // this.alertComponent.dismissibleMiliseconds = this.alertComponent.dismissibleMiliseconds ?? environment.dismissibleMiliseconds;
  }

  protected gotoEditPage() {
    this.router.navigate([`${this.routeToEdit}`, this.entity.id]);
  }
  protected gotoListPage() {
    this.router.navigate([`${this.routeToList}`]);
  }

  // private showModalMsg() {
  //   Swal.fire({
  //     title: 'Registro guardado correctamente',
  //     text: "¿Qué desea hacer ahora?",
  //     showClass: {
  //       popup: 'animated fadeInDown faster',
  //     },
  //     showConfirmButton: true,
  //     showDenyButton: true,
  //     confirmButtonColor: '#3085d6',
  //     denyButtonColor: '#d33',
  //     confirmButtonText: 'Continuar editando',
  //     denyButtonText: 'Ir al listado'
  //   }).then((result) => {



  //     if (result.isConfirmed) {
  //       this.gotoEditPage();
  //     } else {
  //       this.gotoListPage();
  //     }

  //   });
  // }
  // #endregion
  // -------------------------------------------------------------------------------


}
